const STORE_COMMON = [
  {
    store: 'OWNDAYS Plaza Singapura',
    shedules: ['11:00 - 18:00', 'Closed', 'Closed', 'Business as usual'],
  },
  {
    store: 'OWNDAYS 313@somerset',
    shedules: ['11:00 - 18:00', 'Closed', 'Closed', 'Business as usual'],
  },
  {
    store: 'OWNDAYS Bedok Mall',
    shedules: ['11:00 - 18:00', 'Closed', 'Closed', 'Business as usual'],
  },
  {
    store: 'OWNDAYS nex',
    shedules: ['11:00 - 18:00', 'Closed', 'Closed', 'Business as usual'],
  },
  {
    store: 'OWNDAYS Raffles City',
    shedules: ['11:00 - 18:00', 'Closed', 'Closed', 'Business as usual'],
  },
  {
    store: 'OWNDAYS Bugis+',
    shedules: ['11:00 - 18:00', 'Closed', 'Closed', 'Business as usual'],
  },
  {
    store: 'OWNDAYS IMM Outlet',
    shedules: ['11:00 - 18:00', 'Closed', 'Closed', 'Business as usual'],
  },
  {
    store: 'OWNDAYS Suntec City',
    shedules: ['11:00 - 18:00', 'Closed', 'Closed', 'Business as usual'],
  },
  {
    store: 'OWNDAYS JCube',
    shedules: ['11:00 - 18:00', 'Closed', 'Closed', 'Business as usual'],
  },
  {
    store: 'OWNDAYS White Sands',
    shedules: ['11:00 - 18:00', 'Closed', 'Closed', 'Business as usual'],
  },
  {
    store: 'OWNDAYS Tiong Bahru Plaza',
    shedules: ['11:00 - 18:00', 'Closed', 'Closed', 'Business as usual'],
  },
  {
    store: 'OWNDAYS Compass One',
    shedules: ['11:00 - 18:00', 'Closed', 'Closed', 'Business as usual'],
  },
  {
    store: 'OWNDAYS City Square Mall',
    shedules: ['11:00 - 18:00', 'Closed', 'Closed', 'Business as usual'],
  },
  {
    store: 'OWNDAYS Westgate',
    shedules: ['11:00 - 18:00', 'Closed', 'Closed', 'Business as usual'],
  },
  {
    store: 'OWNDAYS 100AM',
    shedules: ['11:00 - 18:00', 'Closed', 'Closed', 'Business as usual'],
  },
  {
    store: 'OWNDAYS Clementi Mall',
    shedules: ['11:00 - 18:00', 'Closed', 'Closed', 'Business as usual'],
  },
  {
    store: 'OWNDAYS Northpoint City',
    shedules: ['11:00 - 18:00', 'Closed', 'Closed', 'Business as usual'],
  },
  {
    store: 'OWNDAYS SingPost Centre',
    shedules: ['11:00 - 18:00', 'Closed', 'Closed', 'Business as usual'],
  },
  {
    store: 'OWNDAYS Seletar Mall',
    shedules: ['11:00 - 18:00', 'Closed', 'Closed', 'Business as usual'],
  },
  {
    store: 'OWNDAYS AMK Hub',
    shedules: ['11:00 - 18:00', 'Closed', 'Closed', 'Business as usual'],
  },
  {
    store: 'OWNDAYS Bukit Panjang Plaza',
    shedules: ['11:00 - 18:00', 'Closed', 'Closed', 'Business as usual'],
  },
  {
    store: 'OWNDAYS Century Square',
    shedules: ['11:00 - 18:00', 'Closed', 'Closed', 'Business as usual'],
  },
  {
    store: 'OWNDAYS PLQ Mall',
    shedules: ['11:00 - 18:00', 'Closed', 'Closed', 'Business as usual'],
  },
  {
    store: 'OWNDAYS Causeway Point',
    shedules: ['11:00 - 18:00', 'Closed', 'Closed', 'Business as usual'],
  },
  {
    store: 'OWNDAYS Parkway Parade',
    shedules: ['11:00 - 18:00', 'Closed', 'Closed', 'Business as usual'],
  },
  {
    store: 'OWNDAYS BUGIS JUNCTION',
    shedules: ['11:00 - 18:00', 'Closed', 'Closed', 'Business as usual'],
  },
  {
    store: 'OWNDAYS ION Orchard',
    shedules: ['10:00 - 18:00', 'Closed', 'Closed', 'Business as usual'],
  },
  {
    store: 'OWNDAYS Takashimaya S.C Premium Concept Store',
    shedules: ['10:00 - 18:00', 'Closed', 'Closed', 'Business as usual'],
  },
];

export const STORE_LIST_2023 = [
  ...STORE_COMMON,
  {
    store: 'OWNDAYS Jurong Point',
    shedules: ['11:00 - 18:00', '12:00 - 21:00', '12:00 - 22:00', 'Business as usual'],
  },
  {
    store: 'OWNDAYS Vivo City',
    shedules: ['11:00 - 18:00', '12:00 - 21:00', '12:00 - 22:00', 'Business as usual'],
  },
  {
    store: 'OWNDAYS Waterway Point',
    shedules: ['11:00 - 18:00', '12:00 - 21:00', '12:00 - 22:00', 'Business as usual'],
  },
  {
    store: 'OWNDAYS MBS Premium Concept Store',
    shedules: ['11:00 - 23:00', '11:00 - 23:00', '11:00 - 23:00', 'Business as usual'],
  },
  {
    store: 'OWNDAYS Jewel Changi',
    shedules: ['10:00 - 18:00', '10:00 - 22:00', '10:00 - 22:00', 'Business as usual'],
  },
];

export const STORE_LIST_2022 = [
  ...STORE_COMMON,
  {
    store: 'OWNDAYS Jurong Point',
    shedules: ['11:00 - 18:00', '12:00 - 21:00', '12:00 - 22:00', 'Business as usual'],
  },
  {
    store: 'OWNDAYS Vivo City',
    shedules: ['11:00 - 18:00', '12:00 - 21:00', '12:00 - 22:00', 'Business as usual'],
  },
  {
    store: 'OWNDAYS Waterway Point',
    shedules: ['11:00 - 18:00', '12:00 - 21:00', '12:00 - 22:00', 'Business as usual'],
  },
  {
    store: 'OWNDAYS MBS Premium Concept Store',
    shedules: ['11:00 - 22:00', '11:00 - 22:00', '11:00 - 22:00', 'Business as usual'],
  },
  {
    store: 'OWNDAYS Jewel Changi',
    shedules: ['11:00 - 18:00', '12:00 - 21:00', '12:00 - 21:00', 'Business as usual'],
  },
];

export const STORE_LIST_2021 = [
  ...STORE_COMMON,
  {
    store: 'OWNDAYS Jurong Point',
    shedules: ['11:00 - 18:00', '12:00 - 21:00', '12:00 - 22:00', 'Business as usual'],
  },
  {
    store: 'OWNDAYS Vivo City',
    shedules: ['11:00 - 18:00', '12:00 - 21:00', '12:00 - 22:00', 'Business as usual'],
  },
  {
    store: 'OWNDAYS Waterway Point',
    shedules: ['11:00 - 18:00', '12:00 - 21:00', '12:00 - 22:00', 'Business as usual'],
  },
  {
    store: 'OWNDAYS MBS Premium Concept Store',
    shedules: ['11:00 - 22:00', '11:00 - 22:00', '11:00 - 22:00', 'Business as usual'],
  },
  {
    store: 'OWNDAYS Jewel Changi',
    shedules: ['11:00 - 18:00', '10:00 - 22:00', '10:00 - 22:00', 'Business as usual'],
  },
];
