import React, { Fragment } from 'react';

import Breadcrumbs from '../../CommonComponents/Breadcrumbs/Breadcrumbs';

import './HeaderSenichisaku.scss';

export const HeaderSenichisaku = ({ baseImgUrl }) => {
  const breadcrumbs = [
    { path: '/', label: 'Homepage' },
    { path: '/sg/en/news', label: 'News' },
    { path: '#', label: '千一作' },
  ];

  return (
    <Fragment>
      <Breadcrumbs crumbs={breadcrumbs} />

      <section className="header-senichisaku__main">
        <picture>
          <source media="(min-width: 768px)" srcSet={`${baseImgUrl}/main_pc.webp`} />
          <img
            alt="MIJ/Sabae/handmade/glasses"
            className="header-senichisaku__main__bg"
            data-aos="fade-in"
            src={`${baseImgUrl}/main_sp.webp`}
            loading="lazy"
          />
        </picture>

        <div className="base-senichisaku__container">
          <h1 className="header-senichisaku__main__title" data-aos="fade-up">
            <img alt="千一作senichisaku" src={`${baseImgUrl}/logo.svg`} loading="lazy" />
            <span>SENICHISAKU</span>
          </h1>

          <p className="header-senichisaku__main__text" data-aos="fade-up" data-aos-delay="300">
            The Origin of the Finest <br className="base-senichisaku__hide--pc" />
            Japanese Craftsmanship
          </p>
        </div>
      </section>
    </Fragment>
  );
};
