import React from 'react';

const Cover = ({ info }) => {
  return (
    <div className="information-details__cover">
      <img
        alt={info?.storeName ? info?.storeName : info?.title}
        loading="lazy"
        src={
          info?.cover
            ? info?.cover
            : 'https://static.lenskart.com/media/owndays/img/information/no-image.webp'
        }
      />
    </div>
  );
};

export default Cover;
