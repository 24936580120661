import React from 'react';
import Redirect from '../CommonComponents/Redirect/Redirect.js';
import PolicyHTML from '../own-days/html/PolicyHTML.jsx';
import PolicyWithEmailHTML from '../own-days/html/PolicyWithEmailHTML.jsx';
import ResultSubmitHTML from '../own-days/html/ResultSubmitHTML.jsx';
import EyeCampHTML from '../own-days/html/EyeCampHTML';
import Articles from '../own-days/views/Articles';
import SG001 from '../own-days/views/ArticleDetails/SG001.jsx';
import SG002 from '../own-days/views/ArticleDetails/SG002.jsx';
import SG003 from '../own-days/views/ArticleDetails/SG003.jsx';
import NewsPage from '../own-days/html/News.jsx';
import KuromiCollectionHTML from '../own-days/html/KuromiCollectionHTML.jsx';
import ContactCategory from '../own-days/views/contacts/ContactCategory';
import ContactType from '../own-days/views/contacts/ContactType';
import HowToUse from '../own-days/views/contacts/HowToUse';
import FreaksStoreHTML from '../own-days/html/FreaksStoreHTML.jsx';
import AirHTML from '../own-days/html/AirHTML.jsx';
import PrivacyPolicyPage from '../own-days/views/desktop/PrivacyPolicyPage/PrivacyPolicyPage';
import TermsConditionsPage from '../own-days/views/desktop/TermsConditionsPage/TermsConditionsPage';
import KimetsuCollectionHTML from '../own-days/html/KimetsuCollectionHTML';
import Information from '../own-days/views/information';
import InformationDetails from '../own-days/views/InformationDetails';
import Owndays1For1ContactLensDealHTML from '../own-days/html/Owndays1For1ContactLensDealHTML.jsx';
import PompompurinHTML from '../own-days/html/PompompurinHTML.jsx';
import ContactUsHTML from '../own-days/html/ContactUsHTML';
import SenichisakuHTML from '../own-days/html/SenichisakuHTML';
import SunLP from '../own-days/LP/News/Sun/SunLP';

const DeactivateOwndays1For1ContactLensDealHTML = () => {
  const currentDate = new Date();
  const deactivateDate = new Date('2024-12-31T16:00:00Z'); // SG 2025-01-01T00:00:00

  if (currentDate >= deactivateDate) {
    return <Redirect to="/news" />;
  }
  return <Owndays1For1ContactLensDealHTML />;
};

const commonRoutes = [
  // PDPA Routes
  { path: '/(sg/en)?/pdpa/completed', component: ResultSubmitHTML },
  { path: '/(sg/en)?/pdpa', component: PolicyHTML },
  { path: '/(sg/en)?/pdpa2', component: PolicyWithEmailHTML },

  // Privacy Policy / Terms and Conditions Routes
  { path: '/(sg/en)?/privacy', component: PrivacyPolicyPage },
  { path: '/(sg/en)?/terms', exact: true, component: TermsConditionsPage },
  { path: '/(sg/en)?/privacy-policy', component: () => <Redirect to="/privacy" /> },
  { path: '/(sg/en)?/terms-conditions', component: () => <Redirect to="/terms" /> },

  // Articles Routes
  { path: '/(sg/en)?/articles/sg-001', component: SG001 },
  { path: '/(sg/en)?/articles/sg-002', component: SG002 },
  { path: '/(sg/en)?/articles/sg-003', component: SG003 },
  { path: '/(sg/en)?/articles', exact: true, component: Articles },

  // News Routes
  { path: '/(sg/en)?/news/kuromi', component: KuromiCollectionHTML },
  {
    path: '/(sg/en)?/news/freaks-store',
    component: FreaksStoreHTML,
  },
  { path: '/(sg/en)?/news/air', component: AirHTML },
  {
    path: '/(sg/en)?/news/kimetsu',
    component: KimetsuCollectionHTML,
  },
  {
    path: '/(sg/en)?/news/contact-lens-deal',
    component: DeactivateOwndays1For1ContactLensDealHTML,
  },
  { path: '/(sg/en)?/news/pompompurin', component: PompompurinHTML },
  {
    path: '/(sg/en)?/news/senichisaku',
    component: SenichisakuHTML,
  },
  {
    path: '/(sg/en)?/news/sun',
    component: SunLP,
  },
  { path: '/(sg/en)?/news', exact: true, component: NewsPage },

  // Company Routes
  { path: '/(sg/en)?/company/eyecamp', component: EyeCampHTML },

  // Contacts Routes
  { path: '/(sg/en)?/contacts/:category', exact: true, component: ContactCategory },
  { path: '/(sg/en)?/contacts/:category/how-to-use', exact: true, component: HowToUse },
  { path: '/(sg/en)?/contacts/:category/:type', component: ContactType },
  { path: '/(sg/en)?/contact', component: ContactUsHTML },

  // Information Routes
  { path: '/(sg/en)?/information', exact: true, component: Information },
  { path: '/(sg/en)?/information/:id(\\d+)', component: InformationDetails },

  // Redirect Routes
  { path: '/home-try-on-program-*', component: () => <Redirect to="/bookeyetest" /> },
  {
    path: '/(home-eye-check-up.html|home-eye-check-up-for-rs-5-plus-get-a-rs-1200-voucher.html|home-try-on-program.html|eyetest)',
    component: () => <Redirect to="/bookeyetest" />,
  },
  { path: '/(sg/en)?/news/clear-sunglasses', component: () => <Redirect to="/news" /> },
  { path: '/(sg/en)?/news/cinnamoroll', component: () => <Redirect to="/news" /> },
  { path: '/(sg/en)?/news/enhanced-lens-offer', component: () => <Redirect to="/" /> },
  { path: '/(sg/en)?/news/airultem', component: () => <Redirect to="/news/air" /> },
  { path: '/(sg/en)?/news/eco2xy', component: () => <Redirect to="/news/air" /> },
  { path: '/(sg/en)?/news/memorymetal', component: () => <Redirect to="/news/air" /> },
  { path: '/(sg/en)?/news/owndays-plus', component: () => <Redirect to="/news/air" /> },
  { path: '/(sg/en)?/news/owndays-snap', component: () => <Redirect to="/news/sun" /> },
  { path: '/(sg/en)?/news/sunglasses', component: () => <Redirect to="/news/sun" /> },
  { path: '/(sg/en)?/eyeglasses/brands/snap.html', component: () => <Redirect to="/brands" /> },
  {
    path: '/(sg/en)?/eyeglasses/brands/Owndaysplus.html',
    component: () => <Redirect to="/brands" />,
  },
  {
    path: '/(sg/en)?/eyeglasses/brands/AIRUltem.html',
    component: () => <Redirect to="/brands" />,
  },
  {
    path: '/(sg/en)?/eyeglasses/brands/MemoryMetal.html',
    component: () => <Redirect to="/brands" />,
  },
  {
    path: '/(sg/en)?/eyeglasses/brands/AIRFIT.html',
    component: () => <Redirect to="/brands" />,
  },
  {
    path: '/(sg/en)?/eyeglasses/brands/eco2xy.html',
    component: () => <Redirect to="/brands" />,
  },
  {
    path: '/(sg/en)?/news/huawei',
    component: () => <Redirect to="/news/huawei2" />,
  },
  {
    path: '/(sg/en)?/news/owndays-plus2023',
    component: () => <Redirect to="/news/owndays-plus" />,
  },
  {
    path: '/(sg/en)?/news/christmas-happenings',
    component: () => <Redirect to="/news" />,
  },
  {
    path: '/(sg/en)?/news/pokemon',
    component: () => <Redirect to="/brands" />,
  },
  { path: '/news/free-myopia-control-lens', component: () => <Redirect to="/news" /> },
  {
    path: '/(sg/en)?/contacts/clear-contactlens/daily-advance',
    component: () => <Redirect to="/contacts/clear-contactlens" />,
  },
  {
    path: '/(sg/en)?/contacts/clear-contactlens/monthly-advance',
    component: () => <Redirect to="/contacts/clear-contactlens" />,
  },
  {
    path: '/(sg/en)?/news/owndays-contactlens-sg',
    component: () => <Redirect to="/contacts/clear-contactlens" />,
  },
  {
    path: '/(sg/en)?/news/owndays-contactlens-sg/daily',
    component: () => <Redirect to="/contacts/clear-contactlens/daily" />,
  },
  {
    path: '/(sg/en)?/news/owndays-contactlens-sg/monthly',
    component: () => <Redirect to="/contacts/clear-contactlens/monthly" />,
  },
  {
    path: '/(sg/en)?/news/owndays-contactlens-sg/daily-advance',
    component: () => <Redirect to="/contacts/clear-contactlens/daily-advance" />,
  },
  {
    path: '/(sg/en)?/news/owndays-contactlens-sg/monthly-advance',
    component: () => <Redirect to="/contacts/clear-contactlens/monthly-advance" />,
  },
  {
    path: '/(sg/en)?/news/color-contactlens',
    component: () => <Redirect to="/contacts/color-contactlens" />,
  },
  {
    path: '/(sg/en)?/news/color-contactlens/natural',
    component: () => <Redirect to="/contacts/color-contactlens/natural" />,
  },
  {
    path: '/(sg/en)?/news/color-contactlens/horoscope',
    component: () => <Redirect to="/contacts/color-contactlens/horoscope" />,
  },
  {
    path: '/(sg/en)?/news/color-contactlens/petal',
    component: () => <Redirect to="/contacts/color-contactlens/petal" />,
  },
  {
    path: '/(sg/en)?/news/color-contactlens/velvet',
    component: () => <Redirect to="/contacts/color-contactlens/velvet" />,
  },
  {
    path: '/(sg/en)?/news/ortho-k-lens',
    component: () => <Redirect to="/contacts/orthok-contactlens" />,
  },
  { path: '/(sg/en)?/news/vivocity-event', component: () => <Redirect to="/news" /> },
  { path: '/(sg/en)?/eyeglasses/brands.html', component: () => <Redirect to="/brands" /> },
  { path: '/(sg/en)?/news/ortho-k-webinar', component: () => <Redirect to="/news" /> },
  { path: '/(sg/en)?/campaign/black-friday.html', component: () => <Redirect to="/news" /> },
  { path: '/(sg/en)?/sg-gold-rush', component: () => <Redirect to="/news" /> },
  { path: '/(sg/en)?/news/black-friday-2024', component: () => <Redirect to="/news" /> },
];

export default commonRoutes;
