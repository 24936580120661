import React from 'react';

const Sheet = ({ sheet }) => {
  return (
    <table>
      <thead>
        <tr>
          {sheet?.header?.map(header => (
            <th dangerouslySetInnerHTML={{ __html: header }} key={header} />
          ))}
        </tr>
      </thead>
      <tbody>
        {sheet?.body?.map((row, rowIndex) => (
          <tr key={rowIndex}>
            <td>
              <a href="https://www.owndays.com/stores/sg">{row?.store}</a>
            </td>
            {row?.shedules?.map((shedule, sheduleIndex) => (
              <td key={sheduleIndex}>{shedule}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Sheet;
