import React from 'react';
import { INFORMATION_DATA } from '../../constants/information';
import Breadcrumbs from '../../CommonComponents/Breadcrumbs/Breadcrumbs';
import Header from './components/Header';
import Cover from './components/Cover';
import StoreDetails from './components/StoreDetails';
import Access from './components/Access';
import Contents from './components/Contents';
import Sns from './components/Sns';
import Footer from './components/Footer';
import './InformationDetails.scss';

const InformationDetails = ({ match }) => {
  const { id } = match.params;
  const info = INFORMATION_DATA?.find(i => i?.id === Number(id));

  if (!info) {
    return <p>Not found.</p>;
  }

  const crumbs = [
    { path: '/sg/en', label: 'Homepage' },
    { path: '/sg/en/information', label: 'See all the Information' },
    { path: `/sg/en/information/${id}`, label: info?.breadcrumb ? info?.breadcrumb : info?.title },
  ];

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <main className="information-details">
        <Header info={info} />
        {info?.cover && <Cover info={info} />}
        <div className="information-details__container">
          <div className="information-details__body">
            {info?.storeDetails && <StoreDetails storeDetails={info?.storeDetails} />}
            {info?.grandOpening && <Contents contents={info?.grandOpening} />}
            {info?.access && <Access access={info?.access} />}
            {info?.contents && <Contents contents={info?.contents} />}
          </div>
          <Sns infoId={info?.id} />
        </div>
        <Footer />
      </main>
    </>
  );
};

export default InformationDetails;
