import React, { useEffect, useState } from 'react';
import './SelectLenses.scss';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../../../actionCreators/cart';
import { showGlobalHeaderFooter } from '../../../actionCreators/header';
import { apiPath } from '../../../config';
import { odPushGA4Event } from '../../../utils/gaFour';
import { odPushTTQEvent } from '../../../utils/tiktokPixel';
import { setToastMessage } from '../../../actionCreators/common';
import SelectLensesHeader from './SelectLensesHeader';
import PrescriptionType from './PrescriptionType';
import Packages from './Packages';
import ButtonOwnDays from '../../../CommonComponents/buttons/ButtonOwnDays/ButtonOwnDays';
import apiClient from '../../../helpers/apiClient';
import { odPushClevertapEvent } from '../../../utils/clevertap';
import { callClevertapEventWithCallback, getCurrencyCode, getProductPrice } from '../../helper';
import Addons from './Addons';
import SelectLensesModalProgress from './SelectLensesModalProgress';
import useModalScrollLock from '../../hooks/useModalScrollLock';

const SELECT_LENS = 'select_lens';
const SELECT_ADDONS = 'select_addons';
const SELECT_PRESCRIPTION = 'select_prescription';
const FRAME_ONLY = 'frame_only';

const getProductName = (productName, color) => {
  const newName = productName
    ?.replace('C1', color)
    .replace('C2', color)
    .replace('C3', color)
    .replace('C4', color)
    .replace('C5', color)
    .replace('C6', color)
    .replace('C7', color);

  return newName || productName;
};

const SelectLensesModal = props => {
  const isLensesSelectionPopupOpen = useSelector(state => state.product.isLensesSelectionPopupOpen);
  const isCartDataLoaded = useSelector(state => state?.cart?.isCartDataLoaded);
  const [addingToCart, setAddingToCart] = useState(false);
  useModalScrollLock(isLensesSelectionPopupOpen);

  const { handleModal } = props;
  const [selectedPrescriptionType, setSelectedPrescriptionType] = useState(null);
  const [currentStep, setCurrentStep] = useState(SELECT_PRESCRIPTION);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedCoating, setSelectedCoating] = useState(null);
  const [packages, setPackages] = useState([]);
  const [packagesZeroPower, setPackagesZeroPower] = useState([]);

  const dispatch = useDispatch();
  const product = props.productData;
  const productId = product?.id;

  const userInfo = useSelector(state => state.common.userInfo);
  const platform = useSelector(state => state.DTM.platform);

  const price = getProductPrice(product);

  const steps = {
    select_prescription: {
      label: getProductName(product?.fullName, product?.frameColour),
    },
    select_lens: {
      label: getProductName(product?.fullName, product?.frameColour),
    },
    frame_only: {
      label: getProductName(product?.fullName, product?.frameColour),
    },
    select_addons: {
      label: getProductName(product?.fullName, product?.frameColour),
    },
  };

  useEffect(() => {
    if (addingToCart) {
      if (isCartDataLoaded) {
        if (selectedPrescriptionType === 'frame_only') {
          callClevertapEventWithCallback(
            'Added to Cart',
            {
              productData: product,
              packageId: null,
              coatingId: null,
              prescriptionType: 'Frame Only',
            },
            () => {
              window.location.href = '/sg/en/cart';
            }
          );
        }

        if (selectedCoating && selectedPackage && selectedPrescriptionType) {
          callClevertapEventWithCallback(
            'Added to Cart',
            {
              productData: product,
              packageName: selectedPackage?.label,
              coatingName: selectedCoating?.title,
              prescriptionType:
                selectedPrescriptionType === 'zero_power' ? 'zero_power' : 'single_vision',
            },
            () => {
              window.location.href = '/sg/en/cart';
            }
          );
        }
        setAddingToCart(false);
      }
    }
  }, [addingToCart, isCartDataLoaded]);

  useEffect(() => {
    setSelectedCoating(null);
    setSelectedPackage(null);
  }, [selectedPrescriptionType]);

  useEffect(() => {
    if (selectedPrescriptionType) {
      odPushGA4Event('cta_click', {
        userInfo,
        platform,
        ctaName: `${product.type}-package-screen-${product.color}-${
          selectedPrescriptionType ? 'Power' : 'No-Power'
        }-${product.type === 'Eyeglasses' || product.type === 'eyeframe' ? 'Eye' : 'Sun'}`,
        ctaFlowAndPage: `${product.type}-package-screen`,
      });
    }
  }, [platform, product, selectedPrescriptionType, userInfo]);

  useEffect(() => {
    dispatch(showGlobalHeaderFooter({ header: false, footer: false }));

    odPushGA4Event('page_view', {
      screenName: 'add-lens-type-page',
      userInfo,
      platform,
    });

    odPushClevertapEvent('Page Viewed', {
      pageName: 'Select Lens Page',
      pageUrl: window.location.href,
    });

    return () => {
      dispatch(showGlobalHeaderFooter({ header: true, footer: true }));
    };
  }, []);

  useEffect(() => {
    if (product) {
      const client = apiClient();
      client.setHeader();
      client
        .get(
          `${apiPath.lensPackages}/${productId}/packages?power_type=single_vision&frame_type=${product?.frameTypeKey}`
        )
        .then(res => {
          if (Number(res?.status) === 200) {
            const packs = res?.result;
            if (packs?.packages?.length > 0) {
              setPackages(packs?.packages);
            } else {
              setPackages([]);
            }
          } else {
            setPackages([]);
          }
        })
        .catch(() => {
          setPackages([]);
        });

      client
        .get(
          `${apiPath.lensPackages}/${productId}/packages?power_type=zero_power&frame_type=${product?.frameTypeKey}`
        )
        .then(res => {
          if (Number(res?.status) === 200) {
            const packs = res?.result;
            if (packs?.packages?.length > 0) {
              setPackagesZeroPower(packs?.packages);
            } else {
              setPackagesZeroPower([]);
            }
          } else {
            setPackagesZeroPower([]);
          }
        })
        .catch(() => {
          setPackagesZeroPower([]);
        });
    }
  }, [product]);

  useEffect(() => {
    if (!product?.id) return;

    const gaAddToCartData = JSON.parse(sessionStorage.getItem('ga-add-to-cart-data')) || {};

    sessionStorage.setItem(
      'ga-add-to-cart-data',
      JSON.stringify({
        ...gaAddToCartData,
        [product.id]: {
          item_category3: selectedPackage?.id,
          item_category4: selectedCoating?.id,
        },
      })
    );
  }, [product?.id, selectedPackage, selectedCoating]);

  const onContinue = () => {
    if (
      (selectedPrescriptionType === 'single_vision' || selectedPrescriptionType === 'zero_power') &&
      currentStep === SELECT_PRESCRIPTION
    ) {
      setCurrentStep(SELECT_LENS);
      return;
    }

    if (selectedPackage && currentStep === SELECT_LENS) {
      setCurrentStep(SELECT_ADDONS);
      return;
    }

    if (selectedPrescriptionType === 'frame_only' && currentStep === SELECT_PRESCRIPTION) {
      setCurrentStep(FRAME_ONLY);
      return;
    }

    const gaPLPSelectItemData = JSON.parse(sessionStorage.getItem('ga-plp-select-item-data')) || {};
    const gaAddToCartData = JSON.parse(sessionStorage.getItem('ga-add-to-cart-data')) || {};

    if (!selectedPrescriptionType) {
      dispatch(setToastMessage({ message: 'Please select prescription type', timeout: 2000 }));
      return;
    }

    if (!selectedCoating && selectedPrescriptionType !== 'frame_only') {
      dispatch(setToastMessage({ message: 'Please select package', timeout: 2000 }));
      return;
    }

    if (selectedCoating && selectedPackage && selectedPrescriptionType) {
      odPushGA4Event('add_to_cart', {
        userInfo,
        productData: {
          ...product,
          selectedCoating,
          selectedPackage,
          selectedPrescriptionType:
            selectedPrescriptionType === 'zero_power' ? 'zero_power' : 'single_vision',
        },
        gaPLPSelectItemData,
        gaAddToCartData,
      });

      // odPushClevertapEvent('Added to Cart', {
      //   productData: product,
      //   packageId: selectedPackage?.id,
      //   coatingId: selectedCoating?.id,
      //   prescriptionType:
      //     selectedPrescriptionType === 'zero_power' ? 'zero_power' : 'single_vision',
      // });

      odPushTTQEvent('AddToCart', {
        userInfo,
        productData: product,
      });

      dispatch(
        addToCart({
          productId,
          powerType: selectedPrescriptionType === 'zero_power' ? 'zero_power' : 'single_vision',
          packageId: selectedPackage?.id,
          addOns: selectedCoating?.id,
        })
      );

      setAddingToCart(true);
    }

    // If I know my power open select lens screen and select lens
    // If I don't know my power add to cart without lens
    // Without prescription open select lens
    if (selectedPrescriptionType === 'frame_only') {
      // odPushClevertapEvent('Added to Cart', {
      //   productData: product,
      //   packageId: null,
      //   coatingId: null,
      //   prescriptionType: 'Frame Only',
      // });

      // add to cart without select lens
      dispatch(addToCart({ productId, powerType: 'Frame Only' }));
      setAddingToCart(true);
    }
  };

  const handleBack = () => {
    if (currentStep === SELECT_LENS || currentStep === FRAME_ONLY) {
      setCurrentStep(SELECT_PRESCRIPTION);
      setSelectedPrescriptionType(null);
      setSelectedPackage(null);
      setSelectedCoating(null);
    }

    if (currentStep === SELECT_PRESCRIPTION) {
      handleModal(false);
    }

    if (currentStep === SELECT_ADDONS) {
      setCurrentStep(SELECT_LENS);
    }
  };

  const onCross = () => {
    handleModal(false);
  };

  const shouldDisableSubmitBtn = () => {
    if (selectedPrescriptionType === 'frame_only') {
      return false;
    }

    if (selectedCoating && selectedPackage && selectedPrescriptionType) {
      return false;
    }

    if (
      (selectedPrescriptionType === 'single_vision' || selectedPrescriptionType === 'zero_power') &&
      currentStep === SELECT_PRESCRIPTION
    ) {
      return false;
    }

    if (selectedPackage && currentStep === SELECT_LENS) {
      return false;
    }

    return true;
  };

  if (product) {
    return (
      <div className="odslv-modal">
        <SelectLensesModalProgress currentStep={currentStep} />
        <div className="odslv-modal__right">
          <div className="odslv-modal__right__main">
            <div className="odslv-modal__right__main__content">
              <SelectLensesHeader
                currentStep={currentStep}
                steps={steps}
                onBack={handleBack}
                onCross={onCross}
              />
              {currentStep === SELECT_PRESCRIPTION && (
                <PrescriptionType
                  selectedPrescriptionType={selectedPrescriptionType}
                  setSelectedPrescriptionType={setSelectedPrescriptionType}
                />
              )}
              {currentStep === SELECT_LENS && (
                <div>
                  <h1 className="select-lens-page-title">Select lens type</h1>
                  <Packages
                    packages={
                      selectedPrescriptionType === 'zero_power' ? packagesZeroPower : packages
                    }
                    selectedPackage={selectedPackage}
                    setSelectedPackage={setSelectedPackage}
                  />
                </div>
              )}
              {currentStep === SELECT_ADDONS && (
                <Addons
                  packages={
                    selectedPrescriptionType === 'zero_power' ? packagesZeroPower : packages
                  }
                  selectedCoating={selectedCoating}
                  selectedPackage={selectedPackage}
                  setSelectedCoating={setSelectedCoating}
                />
              )}
              {currentStep === FRAME_ONLY && (
                <div className="od-fram-only-description">
                  <h1 className="select-lens-page-title">Lens Replacement Voucher</h1>
                  <ul>
                    <li>
                      Get a free replacement of standard lenses for your OWNDAYS glasses bought
                      online. Upgrades cost extra.
                    </li>
                    <li>
                      Redeem in OWNDAYS stores in Singapore, but kids under 8 needing an eye test,
                      go to a store with optometrists.
                    </li>
                    <li>
                      The voucher is good for two months. Keep it safe; lost or damaged ones can't
                      be replaced. Terms may change anytime.
                    </li>
                  </ul>
                </div>
              )}
            </div>

            <div className="odslv-modal__right__footer">
              <div className="odslv-modal__right__footer--content"></div>
              <div className="odslv-modal__right__footer--action">
                <div className="odslv-modal__right__footer--action-info">
                  <div>
                    <span className="odslv-modal__right__footer--action-info__price od-font-bold">{`${
                      getCurrencyCode(product) ? getCurrencyCode(product) : `SGD`
                    } ${selectedCoating ? getProductPrice(selectedCoating) + price : price}`}</span>
                    <span className="odslv-modal__right__footer--action-info__description od-font-reg">
                      Total (glasses + lens)
                    </span>
                  </div>
                </div>
                <ButtonOwnDays
                  disabled={shouldDisableSubmitBtn()}
                  fontClass="od-font-reg"
                  fontSize="20px"
                  mode="oval-grey"
                  px="24px"
                  py="16px"
                  text="Next"
                  onClick={onContinue}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default SelectLensesModal;
