import React, { Fragment } from 'react';
import Sheet from './Sheet';

const Contents = ({ contents }) => {
  return (
    <div className="information-details__contents">
      {contents?.map((content, index) => (
        <Fragment key={index}>
          {content?.title && <h2>{content?.title}</h2>}
          <div dangerouslySetInnerHTML={{ __html: content?.html }} className="mb-50" />
          {content?.sheet && <Sheet sheet={content?.sheet} />}
        </Fragment>
      ))}
    </div>
  );
};

export default Contents;
