import { STORE_LIST_2023, STORE_LIST_2022, STORE_LIST_2021 } from './storeList';

export const INFORMATION_GENRES = [
  {
    id: 'latest',
    label: 'Latest Information',
  },
  {
    id: 'notice',
    label: 'Notice',
  },
  {
    id: 'press',
    label: 'Press Release',
  },
];

export const getGenreLabel = genreId => {
  const genre = INFORMATION_GENRES.find(g => g.id === genreId);
  return genre ? genre.label : 'Unknown Genre';
};

export const createMaintenanceNotice = ({ releaseDate, id, date, startTime, endTime }) => ({
  title: 'Urgent System Maintenance Notification',
  releaseDate,
  id,
  genre: 'notice',
  contents: [
    {
      html: `<p>Please note that we have scheduled a server upgrade at the following date and time.<br/>
      During this time, our website will be unavailable.</p>`,
    },
    {
      html: `<h3>Date/Time</h3>
      <p>${date} from ${startTime} to ${endTime}<br/>
      <small>*The scheduled completion time is subject to change without prior notice</small></p>
      <p>We apologise for any inconvenience caused and thank you for your kind understanding.</p>`,
    },
  ],
});

export const createLunarNewYearNotice = ({ year, dates, stores }) => ({
  title: `${year} Lunar New Year Operating Hours`,
  releaseDate: dates.releaseDate,
  id: dates.id,
  genre: 'notice',
  contents: [
    {
      html: `<h3>OWNDAYS Online Store</h3>
      <p>Orders received from ${dates.cutoffTime} on ${dates.eve} to ${dates.day2} will be processed on ${dates.day3}.</p>`,
    },
    {
      html: `<h3>Customer Service Hotline</h3>
      <p>Customer Service Hotline will not be available from ${dates.cutoffTime} on ${dates.eve} to ${dates.day2}. Normal operating hours will resume on ${dates.day3}.</p>`,
    },
    {
      html: `<h3>OWNDAYS Retail Stores</h3>
      <p>Selected OWNDAYS stores will open throughout the Lunar New Year to serve your eyewear needs. Please refer to the list below on each store's opening hours.</p>`,
      sheet: {
        header: [
          'Store',
          `${dates.eve}<br/>Eve of Lunar New Year`,
          `${dates.day1}<br/>Lunar new year day 1`,
          `${dates.day2}<br/>Lunar new year day 2`,
          `${dates.day3}<br/>Lunar new year day 3`,
        ],
        body: stores,
      },
    },
  ],
});

export const INFORMATION_DATA = [
  {
    id: 1048,
    cover: 'https://static.lenskart.com/media/owndays/img/information/2024-12-12_1048.webp',
    releaseDate: '2024-12-12',
    genre: 'latest',
    title: 'Thu. 19 Dec. 2024 OWNDAYS Le Quest Outlet Store New Open!',
    storeName: 'Le Quest Outlet',
    breadcrumb: 'OWNDAYS Le Quest Outlet Store New Open',
    storeDetails: {
      address: '4 Bukit Batok Street 41, #01-67 Le Quest, Singapore 657991',
      operatingHours: '11:00-20:30',
    },
    access: {
      lat: 1.35594,
      long: 103.74116,
    },
  },
  {
    id: 5002,
    releaseDate: '2024-10-31',
    genre: 'press',
    title: 'Announcement on Product Line Changes',
    contents: [
      {
        html: `<p>Through the rebranding, we have restructured our product range to make our eyewear even more attractive and easier to understand for our customers.</p>`,
      },
      {
        title: 'In-House Brands',
        html: `<p>This product range is designed for those seeking the best items for everyday use, with an emphasis on functionality and cost-effectiveness. They are designed for simplicity, high quality, and convenience.</p>`,
      },
      {
        title: 'Sub-Brands',
        html: `<p>This product group stands out for its design and unique concept. A wide variety of designs are available, perfect for those who want to focus on eyewear as a fashion statement.</p>`,
      },
      {
        title: 'Collaborations',
        html: `<p>OWNDAYS collaborates with brands from various industries to create unique items that blend the character of both companies. By collaborating with brands that have distinct backgrounds and values, OWNDAYS offers collections that appeal to an even broader range of styles.</p><img alt="OWNDAYS Announcement on Product Line Changes" class="product-line-change__image" src="https://static1.lenskart.com/media/owndays/img/information/5002/image.webp">`,
      },
    ],
  },
  {
    id: 1043,
    cover: 'https://static.lenskart.com/media/owndays/img/information/1043/main.webp',
    releaseDate: '2024-08-14',
    genre: 'latest',
    title: 'Thu. 15 Aug. 2024 OWNDAYS Toa Payoh Store Now Open!',
    storeName: 'Toa Payoh',
    breadcrumb: 'OWNDAYS Toa Payoh Store Now Open',
    storeDetails: {
      address: '190 Lorong 6 Toa Payoh, #01-552, Singapore 310190',
      operatingHours: '10:30-21:00',
    },
    access: {
      lat: 1.3339249,
      long: 103.8492122,
    },
  },
  {
    id: 5001,
    cover: 'https://www.owndays.com/images/information/20240711/676.webp',
    releaseDate: '2024-07-11',
    genre: 'press',
    title:
      'OWNDAYS REVAMPS BRAND IDENTITY, DEBUTING NEW RETAIL DESIGN IN JAPAN AND SINGAPORE THIS JULY',
    contents: [
      {
        html: `<h3>A new reiteration of the brand logo, tagline, retail design and online store design will be rolled out progressively in all countries</h3><p>Japanese eyewear brand OWNDAYS has unveiled a new logo and brand identity today, marking a significant milestone aimed at creating a more unified retail experience globally. With the introduction of a new tagline “OWN ‘your’ DAYS”, the updated identity embodies OWNDAYS’ dedication to inspiring individuality and confidence among its customers.</p><p>As part of this initiative to elevate the shopping experience and maintain a cohesive brand identity across all touchpoints, OWNDAYS is also transforming its retail design at the LaLaport Tachikawa Tachihi store in Japan and the Northpoint City store in Singapore. The LaLaport Tachikawa Tachihi store reopens today while the Northpoint City store is scheduled to reopen on 19 July 2024. The refreshed assets are being implemented globally across both physical and digital channels while the updated retail design will be launched at new stores and rolled out to existing stores progressively.</p>`,
      },
      {
        title: 'Brand Logo',
        html: `<img alt="" src="https://www.owndays.com/images/information/20240711/rebrand-logo.webp"><p>The new brand logo symbolises OWNDAYS’ evolution as a global brand while staying true to its mission and values. It incorporates a motif that denotes a switch with the concept of “switching on/off”, signifying OWNDAYS’ role in empowering customers worldwide to unlock their best selves through OWNDAYS eyewear. Designed in the corporate colours of black and white, the logo conveys the notion that colours in the space created by OWNDAYS are brought to life by its eyewear and the people wearing them.</p>`,
      },
      {
        title: 'Tagline: OWN ‘your’ DAYS',
        html: `<img alt="" src="https://www.owndays.com/images/information/20240711/OWNyourDAYS-logo_only.webp"><p>The new tagline “OWN ‘your’ DAYS” stems from OWNDAYS’ corporate philosophy of enriching the lives of people associated with it. By inspiring confidence and positivity through innovative eyewear solutions, OWNDAYS is empowering customers to shape their own narratives and embrace their unique stories.</p>`,
      },
      {
        title: 'Revamped Retail Design',
        html: `<h3>Location: LaLaport Tachikawa Tachihi (Japan)</h3><p>Opening Date: 11 July 2024 (Thu)</p><img alt="" src="https://www.owndays.com/images/information/20240711/676.webp"><h3>Location: Northpoint City (Singapore)</h3><p>Opening Date: 19 July 2024 (Fri)</p><img alt="" src="https://www.owndays.com/images/information/20240711/968.webp">`,
      },
      {
        title: 'Statement from the CEO, Umiyama Takeshi',
        html: `<p>“At OWNDAYS, customers are at the heart of everything we do. Our minimalist yet sophisticated store design creates an inviting space where customers can explore our latest eyewear collection at ease. The essence of OWN ‘your’ DAYS is embodied in our retail space, greeting customers with a welcoming ambience that is bright, comfortable, and open.</p><p>As we expand globally, we are excited to introduce this refreshed retail design to new stores across various markets while also updating the logo in existing stores. This brand refresh underscores our unwavering commitment to evolving and becoming a brand loved by customers around the world. We look forward to sharing these exciting changes with our customers.”</p>`,
      },
    ],
  },
  {
    id: 432,
    cover: 'https://www.owndays.com/images/newshops/sg/1028/main.webp',
    releaseDate: '2023-05-08',
    genre: 'latest',
    title: 'Tue. 9 May 2023 OWNDAYS Woodleigh Mall Store Now Open!',
    storeName: 'Woodleigh Mall',
    breadcrumb: 'OWNDAYS Woodleigh Mall Store Now Open',
    storeDetails: {
      address: '11 Bidadari Park, #B1-37 The Woodleigh Mall, Singapore 367803',
      operatingHours: '11:00-22:00',
    },
    access: {
      notes: `<p>By MRT</p>
      <ul><li>Alight at Woodleigh station (NE11) on the North East Line</li></ul>
      <p>By Bus</p>
      <ul><li>Take bus services 13, 100, 107, 133, 135, 147, 155, 853</li></ul>`,
      lat: 1.3387111912736838,
      long: 103.87170845681746,
    },
  },
  createLunarNewYearNotice({
    year: 2023,
    dates: {
      releaseDate: '2023-01-06',
      id: 369,
      cutoffTime: '6pm',
      eve: '21 January',
      day1: '22 January',
      day2: '23 January',
      day3: '24 January',
    },
    stores: STORE_LIST_2023,
  }),
  {
    cover: 'https://static.lenskart.com/media/owndays/img/information/2022-05-17_287.webp',
    title: 'Fri. 20 May 2022 OWNDAYS Takashimaya S.C. Premium Concept Store Now Open!',
    releaseDate: '2022-05-17',
    id: 287,
    genre: 'latest',
    storeName: 'Takashimaya S.C. Premium Concept',
    breadcrumb: 'OWNDAYS Takashimaya S.C. Premium Concept Store Now Open',
    storeDetails: {
      address:
        '391 Orchard Road #B1-30 Takashimaya Shopping Centre, Ngee Ann City, Singapore 238872',
      operatingHours: '10:00-21:30',
    },
    grandOpening: [
      {
        title: 'Grand Opening Special – 3 Days Only!',
        html: `<p><img alt="grand opening special" class="information-details__img-banner" src="https://static.lenskart.com/media/owndays/information/latest/TakashimaPremiumCocept/banner.webp" /></p>
      <p>From 20 – 22 May 2022, join us in celebrating the grand opening of our premium concept store at Takashimaya Shopping Centre, Ngee Ann City!</p>
      <p>We are giving away the following gifts to customers who visit us, with no purchase needed. Doors open at 10am!<br/>🎳 <strong>$100 OWNDAYS digital gift voucher</strong> to the <strong>1st to 33rd walk-in customers</strong> of each day.<br/>A pair of <strong>OWNDAYS sunglasses (worth $78)</strong> of their choice to the <strong>34th to 66th walk-in customers</strong> of each day.</p>
      <p class="mb-0">All you need to do is to:</p>
      <ul class="mb-25"><li>Be the first 66 customers in line to enjoy the exclusive gifts</li><li>Sign up as OWNDAYS member</li><li>Follow <a href="https://www.instagram.com/owndays_sg/" rel="noreferrer" target="_blank">@owndays_sg</a> on Instagram</li><li>Upload a photo taken in-store and tag <a href="https://www.instagram.com/owndays_sg/" rel="noreferrer" target="_blank">@owndays_sg</a> on Instagram</li></ul>
      <p class="mb-0">Terms & Conditions:</p>
      <p>Each customer is entitled to one gift only throughout the 3-day campaign period and must either be an existing OWNDAYS member or to sign up as a new OWNDAYS member. Customers must be physically present in the store to redeem the gift. Gift voucher is valid for one-time use only and must be redeemed at OWNDAYS Takashimaya S.C. Premium Concept Store by 27 May 2022. Gift voucher is valid for the purchase of prescription glasses (i.e., spectacle frame with standard high index aspheric lenses) worth at least $98 and is not valid for the purchase of sunglasses, OWNDAYS PC glasses, contact lenses, accessories, lens replacement service, optional lens upgrades and sale items. Only one gift voucher may be used per transaction and the voucher code must be presented prior to payment. The full value of the gift voucher must be utilised in a single purchase. There is strictly no refund of any kind for any unused value. Gift voucher is non-refundable nor exchangeable for cash or other reward forms and cannot be re-issued. There is strictly no extension of validity period after the gift voucher expires. Sunglasses must be redeemed on the same day. Only sunglasses worth $78 are valid for redemption and designs are available while stocks last. Sunglasses are not covered by the OWNDAYS Customer Guarantees warranty programme. The management reserves the right to amend these terms and conditions at any time without prior notice.</p>`,
      },
    ],
    access: {
      notes: `<p>By MRT</p>
      <ul><li>3-minute walk from Orchard Station (NS22); the mall is directly connected to the station by underground passage</li></ul>
      <p>By Bus</p>
      <ul><li>Take bus services 7, 14, 14e, 16, 65, 106, 111, 123, 175, 502, 502A, 1N, 2N, 3N, 4N, 5N, 6N, NR1, NR2, NR3, NR5, NR6, NR7, NR8 and alight at Lucky Plaza; then take the underpass to the mall</li></ul>`,
      lat: 1.3029003,
      long: 103.834363,
    },
  },
  createLunarNewYearNotice({
    year: 2022,
    dates: {
      releaseDate: '2022-01-14',
      id: 244,
      cutoffTime: '6pm',
      eve: '31 January',
      day1: '1 February',
      day2: '2 February',
      day3: '3 February',
    },
    stores: STORE_LIST_2022,
  }),
  {
    title: 'Email Server Failure Notice',
    releaseDate: '2021-11-11',
    id: 217,
    genre: 'notice',
    contents: [
      {
        html: `<p>The email server failure that occurred at the following time has been resolved.</p>`,
      },
      {
        html: `<h3>Server Downtime</h3><p>11 November 2021, 4:30pm to 12 November 2021, 10:00am</p>`,
      },
      {
        html: `<h3>Services Affected</h3>
        <ul>
        <li>All emails pertaining to your online orders</li>
        <li>Membership registration emails</li>
        <li>Email to reset your password</li>
        <li>All emails pertaining to your eye test appointment</li>
        </ul>
        <br/>
        <p>The system is now fully restored and you will be able to receive email notifications as usual.</p>
        <p>We apologise for the inconvenient caused and thank you for your kind understanding.</p>`,
      },
    ],
  },
  createMaintenanceNotice({
    releaseDate: '2021-10-11',
    id: 193,
    date: '12 October 2021 (Tuesday)',
    startTime: '0:00',
    endTime: '2:00',
  }),
  {
    cover: 'https://static.lenskart.com/media/owndays/img/information/2021-09-22_184.webp',
    title: 'Wed. 29 Sep. 2021 OWNDAYS BUGIS JUNCTION Store Now Open!',
    releaseDate: '2021-09-22',
    id: 184,
    genre: 'latest',
    storeName: 'BUGIS JUNCTION',
    breadcrumb: 'OWNDAYS BUGIS JUNCTION Store New Open',
    storeDetails: {
      address: '200 Victoria Street, #01-58/58A/59/59A Bugis Junction,',
      operatingHours: '11:00-22:00',
    },
    access: {
      notes: `<p>By MRT</p>
      <ul><li>Alight at Bugis MRT station on the East West Line and the Downtown Line</li></ul>
      <p>By Bus</p>
      <ul><li>Take bus services 2, 7, 12, 32, 33, 51, 61, 63, 80, 130, 133, 145, 175, 197, 851, 960 and 980</li></ul>`,
      lat: 1.2993754,
      long: 103.8555261,
    },
  },
  createMaintenanceNotice({
    releaseDate: '2021-08-03',
    id: 162,
    date: '04 August 2021 (Wednesday)',
    startTime: '01:00',
    endTime: '01:30',
  }),
  createLunarNewYearNotice({
    year: 2021,
    dates: {
      releaseDate: '2021-02-05',
      id: 113,
      cutoffTime: '6pm',
      eve: '11 February',
      day1: '12 February',
      day2: '13 February',
      day3: '14 February',
    },
    stores: STORE_LIST_2021,
  }),
  {
    cover: 'https://static.lenskart.com/media/owndays/img/information/2021-02-04_112.webp',
    title: 'Fri. 5 Feb. 2021 OWNDAYS MBS Premium Concept Store Now Open',
    releaseDate: '2021-02-04',
    id: 112,
    genre: 'latest',
    storeName: 'MBS Premium Concept',
    breadcrumb: 'OWNDAYS MBS Premium Concept Store Now Open',
    storeDetails: {
      address: '2 Bayfront Avenue, #B2-117 The Shoppes at Marina Bay Sands, Singapore 018972',
      operatingHours: '11:00-22:00',
    },
    access: {
      notes: `<p>By MRT</p>
      <ul><li>Direct access from Bayfront Station (CE1/DT16) on the Circle Line and Downtown Line</li></ul>
      <p>By Bus</p>
      <ul><li>Take bus services 97, 106, 518, 133 and 502</li></ul>
      <p>By Car</p>
      <ul><li>From Ayer Rajah Expressway (AYE), Central Expressway (CTE) or Kallang-Paya Lebar Expressway (KPE), continue on to Marina Coastal Expressway (MCE) which links directly to Marina Boulevard and Central Boulevard, then head towards Bayfront Avenue and Sheares Avenue</li></ul>`,
      lat: 1.2841161,
      long: 103.8596275,
    },
  },
  createMaintenanceNotice({
    releaseDate: '2021-02-01',
    id: 111,
    date: '02 February 2021 (Tuesday)',
    startTime: '01:00',
    endTime: '01:30',
  }),
  {
    cover: 'https://static.lenskart.com/media/owndays/img/information/2020-12-17_97.webp',
    title: 'Wed. 23 Dec. 2020 OWNDAYS Parkway Parade Now Open',
    releaseDate: '2020-12-17',
    id: 97,
    genre: 'latest',
    storeName: 'Parkway Parade',
    breadcrumb: 'OWNDAYS Parkway Parade Now Open',
    storeDetails: {
      address: '80 Marine Parade Road, #B1-96 to 98 Parkway Parade, Singapore 449269',
      operatingHours: '11:00-22:00',
    },
    access: {
      lat: 1.3013366,
      long: 103.9052333,
    },
  },
  {
    cover: 'https://static.lenskart.com/media/owndays/img/information/2020-09-28_94.webp',
    title: 'Mon. 28 Sep. 2020 OWNDAYS Causeway Point Now Open',
    releaseDate: '2020-09-28',
    id: 94,
    genre: 'latest',
    storeName: 'Causeway Point',
    breadcrumb: 'OWNDAYS Causeway Point Now Open',
    storeDetails: {
      address: '1 Woodlands Square, #02-18/19 Causeway Point, Singapore 738099',
      operatingHours: '11:00-22:00',
    },
    access: {
      notes: `<p>By Train</p>
        <ul>
        <li>Alight at Woodlands MRT station on the North South Line and Thomson-East Coast Line.</li>
        </ul>
        <p>By Bus</p>
        <ul>
        <li>Alight at Woodlands Temporary Bus Interchange.</li>
        </ul>`,
      lat: 1.4360933,
      long: 103.7859471,
    },
  },
];
