import React, { Fragment, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import '../CommonComponents/Owndays1For1ContactLensDeal/BaseOwndays1For1ContactLensDeal.scss';

import {
  HeaderOwndays1For1ContactLensDeal,
  BodyOwndays1For1ContactLensDeal,
  FooterOwndays1For1ContactLensDeal,
} from '../CommonComponents/Owndays1For1ContactLensDeal';
import { odPushClevertapEvent } from '../../utils/clevertap';

const Owndays1For1ContactLensDealHTML = () => {
  useEffect(() => {
    odPushClevertapEvent('Page Viewed', {
      pageName: 'Contact Lens Deals',
      pageUrl: window.location.href,
    });
  }, []);

  const baseImgUrl =
    'https://static.lenskart.com/media/owndays/img/owndays-1-for-1-contact-lens-deal';

  const currentDate = new Date();
  const targetDate = new Date('2025-01-09T00:00:00+08:00'); // 9 Jan 2025, SGT 00:00

  // Check if the current date is past the target date
  if (currentDate >= targetDate) {
    return <Redirect to="/" />;
  }

  return (
    <Fragment>
      <HeaderOwndays1For1ContactLensDeal baseImgUrl={baseImgUrl} />
      <BodyOwndays1For1ContactLensDeal />
      <FooterOwndays1For1ContactLensDeal baseImgUrl={baseImgUrl} />
    </Fragment>
  );
};

export default Owndays1For1ContactLensDealHTML;
